import {findKey, get} from 'lodash'
import {log} from '../../utils/monitoring'
import * as routersWrapper from '../wrappers/routers'
import * as pagesWrapper from '../wrappers/pages'
import * as applicationState from '../services/applicationState'
import * as menusWrapper from '../wrappers/menus'
import { SANTA_MEMBERS_APP_ID } from '../constants'
import { isPlatformPagesRemoveEnabled } from '../../utils/experiments'

const getRouterPattern = (router, pageId, id) =>
    findKey(router.config.patterns, p => get(p, 'appData.appPageId') === pageId || (p.pageId && p.pageId === id))

const removeMenuItemFromAllMenus = async ({editorSDK, routerRef, pageId, id}) => {
    const router = await routersWrapper.getByRef({editorSDK, routerRef})
    const pattern = getRouterPattern(router, pageId, id)
    const removedInnerRoute = pattern.replace(/\/?(.*)/, '$1')
    await menusWrapper.removePatternFromAllMenus({editorSDK, pattern: removedInnerRoute})
}

const createLogWithPayload = (pageId, allPages) => {
    const payload = {tags: {pageId}, extra: {allPages: JSON.stringify(allPages)}}
    return message => log(message, payload)
}

const deleteRouterPattern = async ({editorSDK, routerRef, pageId, id}) => {
    const router = await routersWrapper.getByRef({editorSDK, routerRef})
    const pattern = getRouterPattern(router, pageId, id)
    delete router.config.patterns[pattern]
    await routersWrapper.updateRouterConfig({editorSDK, routerRef, config: router.config})
}

const removeMembersAreaPage = async (options) => {
    if (await isPlatformPagesRemoveEnabled()) {
        return removeMembersAreaPagePlatform(options)
    }
    else {
        return removeMembersAreaPageCustom(options)
    }
}

const getPageToNavigateAfterRemove = async (editorSDK, { pageId, id}) => {
    const allPages = await pagesWrapper.getAllPages({editorSDK})
    const currentPageRef = await pagesWrapper.getCurrentPage({editorSDK})
    let navigateTo = currentPageRef;
    const pageToDelete = allPages.find(p => p.tpaPageId ? p.tpaPageId === pageId : p.id === id)
    if (currentPageRef.id === pageToDelete.id) {
        navigateTo = await pagesWrapper.getMainMembersPageRef({ editorSDK })
    }
    return navigateTo
}

const removeMembersAreaPagePlatform = async ({editorSDK, pageId, id}) => {
    const allPages = await pagesWrapper.getAllPages({editorSDK})
    const page = allPages.find(p => p.tpaPageId ? p.tpaPageId === pageId : p.id == id)
    if (!page) {
        return
    }
    const pageRef = {id: page.id, type: 'DESKTOP'}


    return pagesWrapper.remove({
        editorSDK,
        pageRef,
        shouldShowEditorRemovePanel: false,
        pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, {pageId, id})
    })
}

const removeMembersAreaPageCustom = async ({editorSDK, pageId, id}) => {
    applicationState.setIsDeletingSinglePage(true)

    const allPages = await pagesWrapper.getAllPages({editorSDK})
    const page = allPages.find(p => p.tpaPageId ? p.tpaPageId === pageId : p.id == id)
    const logWithPayload = createLogWithPayload(pageId, allPages)

    if (!page) {
        return
    }

    const pageRef = {id: page.id, type: 'DESKTOP'} // Shouldn't create ref object, but there is no other option
    const routerRef = await routersWrapper.getByPageRef({editorSDK, pageRef})

    if (!routerRef) {
        try {
            logWithPayload('trying to remove a page without routerRef, this is not expected')
            try {
                await pagesWrapper.updatePageData({pageRef, data: {managingAppDefId: SANTA_MEMBERS_APP_ID}})
            } catch(e) {}
            await pagesWrapper.remove({
                pageRef,
                shouldShowEditorRemovePanel: false,
                pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, {pageId, id})
            })
        } catch(e) {
            const message = 'failed to remove a page which was not in the router'
            logWithPayload(message)
            throw new Error(message)
        }
        return;
    }

    try {
        await removeMenuItemFromAllMenus({editorSDK, routerRef, pageId, id})
    } catch(e) {
        const message = 'Failed to remove menu items, reason: ' + e.toString()
        logWithPayload(message)
    }

    try {
        await deleteRouterPattern({editorSDK, routerRef, pageId, id})
    } catch(e) {
        const message = 'Failed to remove router patter when deleting a page, reason: ' + e.toString()
        logWithPayload(message)
    }

    try {
        await routersWrapper.removeRouterPage({
            editorSDK,
            routerRef,
            pageRef,
            pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, {pageId, id})
        })
    } catch(e) {
        const message = 'Failed to remove router page, reason: ' + e.toString()
        logWithPayload(message)
        throw new Error(message)
    }

    try {
        const allPages = await pagesWrapper.getAllPages({ editorSDK })
        const wasNotRemoved = allPages.some(p => p.tpaPageId ? p.tpaPageId === pageId : p.id == id)

        if (wasNotRemoved) {
            log('Page was not removed on removeMembersAreaPageCustom. Retrying remove.')
            await pagesWrapper.remove({
                editorSDK,
                pageRef,
                shouldShowEditorRemovePanel: false,
                pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, {pageId, id})
            })
        }
    } catch(e) {
        const message = 'Page was not removed on removeMembersAreaPageCustom. Page remove retry failed. Reason:' + e.toString()
        logWithPayload(message)
    }
}

export {removeMembersAreaPage}
