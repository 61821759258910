import {instanceValue} from '@wix/wix-instance-parser-js/dist/src'
import {isEqual as _isEqual} from 'lodash'

import * as membersLogic from './membersLogic'
import * as pages from './pages'
import * as componentsWrapper from './wrappers/components'
import * as controllersWrapper from './wrappers/controllers'
import * as pagesWrapper from './wrappers/pages'
import * as pagesService from './services/pages'
import * as pagesGroupWrapper from './wrappers/pagesGroup'
import * as routersWrapper from './wrappers/routers'
import * as menusWrapper from './wrappers/menus'
import * as constants from './constants'
import * as applicationState from './applicationState'
import * as routersService from './services/routers'
import i18next from '../i18n'
import createAppApi from './public-api'
import enforceSequentiality from './enforceSequentiality'
import {initializeMonitoring, toMonitored, log} from '../utils/monitoring'
import {createAppManifest} from './manifest'
import {addApplications, createConnectionConfigs} from './platform-api/addApplications'
import * as menusService from './services/menus'
import {getSiteLocale, getEditorLocale} from '../utils/locale'
import {removeMembersAreaPage} from './platform-api/removeMembersAreaPage'
import {setHorizontalLayout, setSidebarLayout} from './platform-api/layouts'
import {parseStaticsUrlFromEditorScriptUrl} from './services/urls'
import * as appState from './services/applicationState'
import {isOB19052FixEnabled, isManageMemberPagesEnabled, isPlatformisedPagesPanelEnabled} from '../utils/experiments'
import {createBIService} from '../utils/bi'
import * as membersIntegrationApi from './services/integration'

let appToken
let editorSDK
let publicApi
let resolveEditorReady
enforceSequentiality(new Promise(resolve => {
        resolveEditorReady = resolve
}))

// TO DO: Login button element removal is missing
async function removeBrokenInstallation(_editorSDK, _appToken, shouldLog = true) {
    if (shouldLog) {
        const state = await applicationState.getApplicationComponents(_editorSDK, _appToken)
        const isEmpty = await applicationState.isEmpty(_editorSDK, _appToken)
        const isOnlyPageGroupApparent = await applicationState.isOnlyPageGroupApparent(_editorSDK, _appToken)
        const extra = {state: JSON.stringify(state)}

        if (isEmpty) {
            log('Removing components for empty installation', {extra})
        } else if (isOnlyPageGroupApparent) {
            log('Removing components for installation with only page group apparent', {extra})
        } else {
            log('Removing components for broken installation', {extra})
        }
    }

    try {
        await pages.navigateToFirstPrivatePage(editorSDK, appToken)
        // eslint-disable-next-line no-empty
    } catch (e) {}

    await componentsWrapper.removeSospContainer(_editorSDK, _appToken)
    await pagesGroupWrapper.remove(_editorSDK, _appToken)
    await routersWrapper.removeConnectedPages(_editorSDK, _appToken)
    await routersWrapper.removeAllRouters(_editorSDK, _appToken)
    await controllersWrapper.wipeOut(_editorSDK, _appToken)
    await menusWrapper.removeMenus(_editorSDK, _appToken)
}

async function removeComponentsForBrokenInstallation(_editorSDK, _appToken) {
    const successfullyInstalled = await applicationState.isApplicationReady(_editorSDK, _appToken, {shouldLog: true})
    if (!successfullyInstalled) {
        await removeBrokenInstallation(_editorSDK, _appToken)
    }
}

async function maybeInstallMissingRouters() {
    // Just logging for now
    const routers = await routersWrapper.getAll(editorSDK, appToken)

    if (routers.length !== 2) {
        const state = await applicationState.getApplicationComponents(editorSDK, appToken)
        const extra = {
            routers: JSON.stringify(routers),
            applicationState: JSON.stringify(state)
        }
        log('Missing routers when they should be apparent ', {extra})
    }
}

async function maybeFixBrokenMenuItems() {
    const menuIds = menusWrapper.getMenuIds()
    const menus = await Promise.all([
        menusWrapper.getMenuById({editorSDK, appToken, menuId: menuIds.members}),
        menusWrapper.getMenuById({editorSDK, appToken, menuId: menuIds.login}),
        menusWrapper.getMenuById({editorSDK, appToken, menuId: menuIds.icons}),
    ])

    // If all menus aren't apparent, don't fix them - MA state is too broken already
    if (menus.filter(m => !!m).length !== 3) {
        return
    }

    await menusService.maybeCleanUpMenus({editorSDK, appToken})
}

async function maybeFixBrokenInstallation() {
    try {
        await maybeInstallMissingRouters()
        await maybeFixBrokenMenuItems()
    } catch (e) {
        log('An errow was thrown while fixing a broken installation, reason: ' + e)
    }
}

async function onManagePages(appToken, eventPayload) {
    const biService = await createBIService({editorSDK})
    biService.managePagesAddMemberPagesClick({origin: 'editor'})
    editorSDK.editor.openModalPanel(appToken, {
        url: './assets/managePages.html',
        width: 1098,
        height: 696,
        shouldHideHeader: true,
        initialData: eventPayload
    })
}

async function getUniqueNewPublicPageUriSeo(editorSDK, initialPrefix = 'blank') {
    let pageUriSeo

    try {
        const routers = await routersWrapper.getAll(editorSDK)
        const currentPageUris = Object
            .keys(routers.find(router => router.config.type === 'public').config.patterns || {})
            .map(pattern => pattern.split('/{userName}/')[1])

        let counter = 1
        let isUnique

        do {
            pageUriSeo = `${initialPrefix}-${counter}`
            isUnique = !currentPageUris.includes(pageUriSeo)
            counter++
        } while (!isUnique && counter < 1000)
    } catch (e) {
        log('getUniqueNewPublicPageUriSeo failed', {tags: {reason: e.toString()}})
        pageUriSeo = initialPrefix
    }

    return pageUriSeo
}

async function createBlankPage(editorSDK, isPrivate = true) {
    const locale = await getSiteLocale(editorSDK, appToken)
    const i18n = await i18next(appState.getStaticsBaseUrl(), locale)
    const t = i18n.t.bind(i18n)
    const pageUriSEO = isPrivate ? undefined : await getUniqueNewPublicPageUriSeo(editorSDK)
    const pageTitle = isPrivate ? t('Pages_New_Private_Page_Title') : t('Pages_New_Public_Page_Title')
    const pageRef = await pagesWrapper.addPage({editorSDK, pageTitle, pageUriSEO})
    if (!isPrivate) {
        await pagesWrapper.updatePageData({editorSDK, pageRef, pageData: {pageSecurity: {requireLogin: false}, hidePage: false}});
    }
    await pagesService.updatePageWithManagingAppDefId({editorSDK, pageRef})


    const createdPage = await pagesWrapper.getPageData({ editorSDK, pageRef });
    const routers = await routersService.getMembersAreaRouters({editorSDK, appToken}) || {publicRouter: {}, privateRouter: {}}
    createdPage.pageRef = pageRef;
    const apps = [{
        appDefinitionId: createdPage.managingAppDefId,
        pageId: '',
        social: !isPrivate,
        showInLoginMenu: true,
        showInMemberMenu: true,
        loginMenuTitle: false
    }]
    const connectionConfigs = createConnectionConfigs({applications: apps, pages: [createdPage], routers})
    await pagesService.connectPagesToMembers({editorSDK, appToken, pages: connectionConfigs})
    await pagesService.setStateForPages(editorSDK)
}

async function onCreateBlankPageLegacy(appToken, _routers) {
    const locale = await getSiteLocale(editorSDK, appToken)
    const i18n = await i18next(appState.getStaticsBaseUrl(), locale)
    const t = i18n.t.bind(i18n)
    const pageRef = await pagesWrapper.addPage({editorSDK, appToken, pageTitle: t('Pages_New_Page_Title')})
    const menuIds = menusWrapper.getMenuIds()
    const _router = _routers.find(r => r.config.type === 'private')
    const pageUri = routersWrapper.createNewPageRoute(Object.keys(_router.config.patterns))
    await pages.connectPageToMembers({
        editorSDK,
        appToken,
        routerConfig: {appData: {menuOrder: constants.BLANK_PAGE_MENU_ORDER}},
        pageData: {
            pageRef,
            pageUriSEO: pageUri,
            isPrivate: true,
            title: t('Pages_New_Page_Title')
        },
        showInMemberMenu: true,
        showInLoginMenu: false,
        showInIconsMenu: false,
        menuIds: menuIds
    })

    await editorSDK.document.application.reloadManifest()
}

async function onEvent({eventType, eventPayload}) {
    enforceSequentiality(async () => {
        const isReady = await applicationState.isApplicationReady(editorSDK, appToken)
        if (!isReady) {
            return
        }
        try {
            const _routers = await editorSDK.routers.getAll(appToken)
            switch (eventType) {
                case 'createBlankPage': {
                    (await isManageMemberPagesEnabled() === true) ?
                        onManagePages(appToken, eventPayload) :
                        onCreateBlankPageLegacy(appToken, _routers)
                    break
                }
                case 'managePages': {
                    onManagePages()
                    break
                }
                case 'pageDeleted': {
                    // Single page deletion shouldn't escalate and delete all the other dependant pages
                    // This is not a good implementation but we couldn't find another reasonable solution with current design
                    // We should listen for deleted app and delete its dependencies, not for deleted pages and escalate it to other dependent pages
                    if (appState.getIsDeletingSinglePage()) {
                        appState.setIsDeletingSinglePage(false)
                        return
                    }

                    if (await isManageMemberPagesEnabled() === true) {
                        // Workaround for EP-1132
                        const page = routersWrapper.getPageByRole(eventPayload.pageRole, _routers)
                        const appDefinitionId = page && page.appData && page.appData.appDefinitionId
                        if (appDefinitionId === constants.EVENTS_APP_DEF_ID) {
                            membersIntegrationApi.handleVerticalDeletion(appDefinitionId, editorSDK)
                        } else {
                            await pages.removePageFromMenus({editorSDK, appToken, options: eventPayload})
                        }
                    } else {
                        await pages.removePage({editorSDK, appToken, options: eventPayload})
                    }

                    break
                }
                case 'uninstall': {
                    editorSDK.editor.openModalPanel(appToken, {
                        url: './assets/uninstall.html',
                        width: 564,
                        height: 309,
                        shouldHideHeader: true,
                        initialData: eventPayload
                    })
                    break
                }
                case 'removePage': {
                    editorSDK.editor.openModalPanel(appToken, {
                        url: './assets/removePage.html',
                        width: 564,
                        height: 300,
                        shouldHideHeader: true,
                        initialData: eventPayload
                    })
                    break
                }
                case 'removePageNew': {
                    editorSDK.editor.openModalPanel(appToken, {
                        url: './assets/removePageNew.html',
                        width: 564,
                        height: 258,
                        shouldHideHeader: true,
                        initialData: eventPayload
                    })
                    break
                }
                case 'renameRouter':
                    publicApi.hasSocialPages().then(hasSocialPages => {
                        const height = hasSocialPages ? constants.RENAME_ROUTER_PANEL_HEIGHT + 150 : constants.RENAME_ROUTER_PANEL_HEIGHT
                        editorSDK.editor.openModalPanel(appToken, {
                            url: './assets/renameRouter.html',
                            width: 744,
                            height,
                            shouldHideHeader: true,
                            initialData: Object.assign({routers: _routers}, eventPayload)
                        })
                    })
                    break
                case 'componentAddedToStage':
                    await componentsWrapper.handleCompAddedToStage(editorSDK, appToken, eventPayload.compRef)
                    break
                /* end of possibly unused events */
                default:
                    console.log(eventType, eventPayload)
            }
        } catch (e) {
            throw e
        }
    })
}

// For investigation purposes of MA-84
async function verifyMyAccountPage(options) {
    if (options.firstInstall) {
        return
    }

    try {
        const menuItems = await menusWrapper.getMenuItems({editorSDK, appToken, menuId: constants.MENU_IDS.SUB_MENU_ID})
        const myAccountMenuItem = menuItems.find(i => i.link.innerRoute === 'my-account')
        const allPages = await editorSDK.pages.data.getAll(appToken)
        const myAccountPage = allPages.find(p => p.tpaPageId === 'member_info')

        if (!myAccountPage && !!myAccountMenuItem) {
            log('editorLoad: MA-84 My account menu item is there, but the page is missing')
            return
        }
        // eslint-disable-next-line
    } catch (e) {
        log('Verifying My Account page failed', {tags: {reason: e.toString()}})
    }
}

// For investigation purposes of MA-177
async function verifyNoMissingRouterPatterns() {
    try {
        const routers = await routersWrapper.getAll(editorSDK, appToken)
        routers.forEach(router => {
            const patterns = router.config.patterns
            router.pages.forEach(page => {
                const pattern = Object.values(patterns).find(patternValue => page.pageRoles.includes(patternValue.page))
                if (!pattern) {
                    log('editorLoad: MA-177 menu item is missing', {tags: {pageRefId: page.pageRef.id}})
                }
            })
        })
    } catch (e) {
        log('Verifying router patterns failed', {tags: {reason: e.toString()}})
    }
}

async function verifyNoMissingLoginInADI(firstInstall) {
    try {
        if (!appState.getIsADI() || !(await isOB19052FixEnabled())) { return; }

        const header = await editorSDK.siteSegments.getHeader(appToken);
        const headerChildren = await componentsWrapper.getAllComponentChildren({editorSDK, appToken, parentComponentRef: header})
        const childrenTypesPromises = headerChildren.map(childRef => componentsWrapper.getComponentType({editorSDK, appToken, componentRef: childRef}));
        const childrenTypes = await Promise.all(childrenTypesPromises)
        const hasLoginComponent = childrenTypes.includes('wysiwyg.viewer.components.LoginSocialBar')

        if (!hasLoginComponent) {
            log('OB-19052: Login component missing in ADI', {tags: {firstInstall}})
            try {
                const menuIds = menusWrapper.getMenuIds()
                const controllerRef = await controllersWrapper.getController(editorSDK, appToken)
                await componentsWrapper.addLoginButton(editorSDK, appToken, menuIds.login, menuIds.icons, controllerRef, header)
            } catch (e) {
                log('OB-19052: Re-adding login component failed', {tags: {reason: e.toString()}})
            }
        }
    } catch (e) {
        log('OB-19052: Verification of login component failed', {tags: {reason: e.toString()}})
    }
}

async function maybeRemoveLeftoversFromUnsuccessfulInstallation(_editorSDK, _appToken, options) {
    const isEmpty = await applicationState.isEmpty(_editorSDK, _appToken)
    const isReady = await applicationState.isApplicationReady(_editorSDK, _appToken, {shouldLog: false})
    if (options.firstInstall && !isEmpty && !isReady) {
        const state = await applicationState.getApplicationComponents(_editorSDK, _appToken)
        await removeBrokenInstallation(_editorSDK, _appToken, false)
        log('Removing leftover components from previous installations', {extra: {state: JSON.stringify(state)}})
    }
}

async function maybeRemoveEmptyInstallation(_editorSDK, _appToken, options) {
    const isEmpty = await applicationState.isEmpty(_editorSDK, _appToken)
    if (!options.firstInstall && isEmpty) {
        log('Removing components for empty installation as it will not install anyway and will surely fail')
        await removeBrokenInstallation(_editorSDK, _appToken, false)
        return {shouldContinueInitialization: false}
    }
    return {shouldContinueInitialization: true}
}

async function maybeInstallMembersArea(_editorSDK, _appToken, options) {
    if (await membersLogic.shouldInstall(_editorSDK, _appToken, options.firstInstall)) {
        try {
            await toMonitored('install', () => membersLogic.install(_editorSDK, _appToken, options))
        } catch (e) {
            log('Removing initial installation as it failed', {tags: {reason: e.toString()}})
            await removeBrokenInstallation(_editorSDK, _appToken)
            throw e
        }
    }
}

// Old sites pages does not have managingAppDefId, which is needed for platformised pages panel
async function maybeSetManagingAppDefIdForMAPages({editorSDK, options}) {
    if (options.firstInstall) {
        return
    }

    try {
        const routers = await routersWrapper.getAll(editorSDK)
        const pagesRefs = routers.reduce((acc, router) => [...acc, ...router.pages.map(p => p.pageRef)], [])
        await Promise.all(pagesRefs.map(pageRef => pagesService.updatePageWithManagingAppDefId({editorSDK, pageRef})))
    } catch (e) {
        log('Failed to set managingAppDefId for MA pages, reason:', e.toString())
    }
}

async function exposePlatformAppAPI(_editorSDK, _appToken) {
    publicApi = createAppApi(_editorSDK, _appToken)
    await editorSDK.editor.setAppAPI(_appToken, publicApi)
    resolveEditorReady()
}

async function editorReady(_editorSDK, _appToken, options = {}) {
    appToken = _appToken
    editorSDK = _editorSDK

    appState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl))
    appState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE')
    appState.setIsADI(options.origin.type.indexOf('ADI') === 0)

    const biService = await createBIService({editorSDK})

    if (await isPlatformisedPagesPanelEnabled()) {
        await maybeSetManagingAppDefIdForMAPages({editorSDK, options})
    }

    try {
        await initializeMonitoring(editorSDK, options)

        await pagesService.setStateForPages(editorSDK)

        // We see occasions of not entirely deleted MAs without the app actually being installed.
        // This should clean it up and allow a proper installation
        // This shouldn't be happening so need to investigate why this is happening
        await maybeRemoveLeftoversFromUnsuccessfulInstallation(editorSDK, appToken, options)

        // Delete empty MAs which won't install anyway to not cause further errors
        // Also don't expose public API in such case as it will not perform properly without MA components
        const {shouldContinueInitialization} = await maybeRemoveEmptyInstallation(editorSDK, appToken, options)
        if (!shouldContinueInitialization) {
            return resolveEditorReady()
        }

        // MA-84 investigation, making sure My Account page is always there as it has to be
        await verifyMyAccountPage(options)
        // MA-177 investigation, making sure there are no pages without pattern in router
        await verifyNoMissingRouterPatterns()

        // Install MA and delete it if anything goes wrong
        await maybeInstallMembersArea(editorSDK, appToken, options)

        // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
        await maybeFixBrokenInstallation(editorSDK, appToken, options)

        // Expose the platform app API
        await exposePlatformAppAPI(editorSDK, appToken)

        // Remove MA if it is still unsuccessfully installed
        await removeComponentsForBrokenInstallation(editorSDK, appToken, options)

        // OB-19052 investigation, check if login component is missing in ADI
        await verifyNoMissingLoginInADI(options.firstInstall)
    } catch (e) {
        biService.logInstallationFailure(e.toString())
        console.error('membersApplication installation failed.', e)
        console.error('things will certainly not work properly from here on')
        throw new Error('Members Area installation failed: ' + e)
    }
}

async function getAppManifest() {
    const locale = await getEditorLocale(editorSDK, appToken)
    const i18n = await i18next(appState.getStaticsBaseUrl(), locale)
    const appManifest = createAppManifest(editorSDK, i18n.t.bind(i18n))
    return appManifest
}

async function getControllerPresets() {
    return Promise.resolve([])
}

function maybeAddApplications(applications, shouldNavigate) {
    return enforceSequentiality(async () => {
        const isReady = await applicationState.isApplicationReady(editorSDK, appToken)
        if (!isReady) {
            console.warn('Members Area installation was corrupted so the integrations pages will not be added')
            log('Skipping addApplications as the application is not ready and probably already deleted')
            return
        }
        return await toMonitored('editorApi.addApplications', () => addApplications({editorSDK, appToken, applications, shouldNavigate}))
    })
}

async function refreshAppManifest() {
    await pagesService.setStateForPages(editorSDK)
    await editorSDK.document.application.reloadManifest()
}

const exports_ = {
    setDependantApps: payload => {
        return enforceSequentiality(() => toMonitored('editorApi.setDependantApps', async () => {
                const currentDependencies = applicationState.getPageDependencies()
                const {appId, dependencies} = payload
                dependencies.forEach(dependency => {
                    currentDependencies[dependency] = (currentDependencies[dependency] || new Set()).add(appId)
                })
                await applicationState.setPageDependencies(currentDependencies)
            }))
    },
    // Seems like this is still used from blog app settings through Wix.SuperApps.Settings.addApplication
    addApplication: payload => {
        log('Legacy: editorApi.addApplication', {extra: payload})
        return maybeAddApplications([{...payload, method: 'addApplication'}])
    },
    addSection: payload => {
        log('Legacy: editorApi.addSection', {extra: payload})
        return maybeAddApplications([{...payload, method: 'addSection'}])
    },
    addApplicationToSite: payload => {
        log('Legacy: editorApi.addApplicationToSite', {extra: payload})
        return maybeAddApplications([{...payload, method: 'addApplicationToSite'}])
    },
    addApplications: (applications, shouldNavigate) => maybeAddApplications(applications, shouldNavigate),
    getMembersPageRef: ({appDefinitionId, appPageId} = {}) => {
        return routersWrapper.findPageRefByAppData(editorSDK, appToken, appDefinitionId, appPageId)
    },
    removeMembersAreaPage: pageId => enforceSequentiality(async () => {
            return await toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({editorSDK, appToken, pageId}))
        }),
    setHorizontalLayout: () => enforceSequentiality(async () => {
            return await toMonitored('editorApi.setHorizontalLayout', () => setHorizontalLayout({editorSDK, appToken}))
        }),
    setSidebarLayout: () => enforceSequentiality(async () => {
            return await toMonitored('editorApi.setSidebarLayout', () => setSidebarLayout({editorSDK, appToken}))
        }),
    _getIsResponsiveEditor: () => enforceSequentiality(() => {
            return appState.getIsResponsiveEditor()
        }),
    handleVerticalDeletion: verticalAppDefId => membersIntegrationApi.handleVerticalDeletion(verticalAppDefId, editorSDK),
    registerMembersAreaApps: (applications, verticalAppDefId) => membersIntegrationApi.registerMembersAreaApps(applications, verticalAppDefId),
    installRegisteredApps: verticalAppDefId => membersIntegrationApi.installRegisteredApps(verticalAppDefId, editorSDK),
    getRegisteredApps: () => membersIntegrationApi.getRegisteredApps(editorSDK),
    addCustomPage: (isPrivate) => createBlankPage(editorSDK, isPrivate),
    refreshAppManifest
}

export {editorReady, onEvent, getAppManifest, getControllerPresets, exports_ as exports}
